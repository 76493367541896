<template>
  <div>
    <h2>Справочник ответственных лиц</h2>

    <responsible-persons-table></responsible-persons-table>
  </div>
</template>

<script>
import ResponsiblePersonsTable from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsTable";
export default {
  name: "responsiblePersonsMain",
  components: { ResponsiblePersonsTable },

  mounted() {
    this.$store.dispatch("searchResponsiblePersons");
  },
};
</script>

<style scoped></style>
