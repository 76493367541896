<template>
  <div>
    <search-block title="ответственных лиц">
      <el-row :gutter="10">
        <el-col :span="22">
          <el-input
            @input="searchResponsiblePerson"
            placeholder="Поиск по наименованию"
            v-model="search"
          ></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="success" @click="showAddModal">Добавить</el-button>
        </el-col>
      </el-row>
    </search-block>
    <el-table
      :rules="rules"
      stripe
      border
      :data="listResponsiblePersons.content"
    >
      <el-table-column label="Фио" prop="fullname"></el-table-column>
      <el-table-column label="Должность" prop="position"></el-table-column>
      <el-table-column
        label="Организация"
        prop="organization.name"
      ></el-table-column>
      <el-table-column label="Телефон" prop="phoneNumber"></el-table-column>
      <el-table-column label="Документ" prop="document.name"></el-table-column>
      <el-table-column label="" width="150">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="width: 100%"
                @click="edit(item.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                @click="deletePerson(item.row)"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="listResponsiblePersons.size"
      :current-page.sync="listResponsiblePersons.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="listResponsiblePersons.totalElements"
    >
    </el-pagination>

    <custom-modal
      title="Добавить ответственного"
      :visible="showModal === 'addResponsiblePerson'"
    >
      <responsible-persons-add></responsible-persons-add>
    </custom-modal>

    <custom-modal
      title="Изменить ответственного"
      :visible="showModal === 'updateResponsiblePerson'"
    >
      <responsible-persons-edit
        :selected-responsible-person="selectedResponsiblePerson"
      ></responsible-persons-edit>
    </custom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchBlock from "@/components/searchBlock";
import CustomModal from "@/components/customModal";
import ResponsiblePersonsAdd from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsAdd";
import notification from "@/mixins/notification";
import ResponsiblePersonsEdit from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsEdit";

export default {
  name: "responsiblePersonsTable",
  components: {
    ResponsiblePersonsEdit,
    ResponsiblePersonsAdd,
    CustomModal,
    SearchBlock,
  },
  mixins: [notification],
  data() {
    return {
      search: null,
    };
  },

  computed: {
    ...mapGetters({
      listResponsiblePersons: "GETLISTRESPONSIBLEPERSON",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    handleSizeChange(val) {
      this.getAllResponsiblePerson({ page: 0, size: val });
    },

    handleCurrentChange(val) {
      this.getAllResponsiblePerson({
        page: val - 1,
        size: this.listResponsiblePersons.size,
      });
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addResponsiblePerson");
    },

    searchResponsiblePerson(query) {
      this.$store.dispatch("searchResponsiblePerson", {
        query: query,
        page: 0,
        size: 20,
      });
    },

    edit(item) {
      this.selectedResponsiblePerson = item;
      this.$store.dispatch("getDocsNumbersSearch", {
        query: item.document.name,
      });
      this.$store.dispatch("setShowModal", "updateResponsiblePerson");
    },

    deletePerson(item) {
      console.log(item);
      this.confirm(
        "Удалить ответственного",
        "Вы действительно хотите удалить ответственного?",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store
          .dispatch("deleteResponsiblePerson", item.personId)
          .then((response) => {
            console.log(response.data);
            this.notification("Успешно", "Ответственный удален", "success");
            this.getAllResponsiblePerson({
              page: this.listResponsiblePersons.number,
              size: this.listResponsiblePersons.size,
            });
          });
      });
    },

    getAllResponsiblePerson(data) {
      this.$store.dispatch("getAllResponsiblePerson", data);
    },
  },

  mounted() {
    this.getAllResponsiblePerson({ page: 0, size: 20 });
  },
};
</script>

<style scoped></style>
