<template>
  <div>
    <responsible-persons-main></responsible-persons-main>
  </div>
</template>

<script>
import ResponsiblePersonsMain from "@/components/ecologist/classifiers/responsiblePersons/responsiblePersonsMain";
export default {
  name: "responsiblePersonsMainView",
  components: { ResponsiblePersonsMain },
};
</script>

<style scoped></style>
